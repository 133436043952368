import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, VSection } from "components/common"
import { PrimaryNav, MobileNav } from "src/components/navigation"
import { useContentfulSection, useIframeBreak, useMeta } from "utils"

const Success = ({ data: { success } }) => {
  const {
    free_support_success_content: { title, subtitle },
  } = useContentfulSection(success.sections)

  const meta = useMeta(success)

  useIframeBreak()

  return (
    <>
      <MobileNav />
      <PrimaryNav />
      <Wrap>
        <Layout hideFooter>
          <Seo {...meta} />

          <VSection
            titleAs="h1"
            align="left"
            title={title}
            subtitle={subtitle}
          />
        </Layout>
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  --section-align: center;
  --tg-text-align: center;
  /* background: var(--section-background-color); */
`

export const query = graphql`
  {
    success: contentfulPage(pageId: { eq: "free_support_success" }) {
      ...Page
    }
  }
`

export default Success
